<template>
  <div class="pages">
    <div class="houses-tab">
      <div class="tab" :class="{active:aindex === index}" v-for="(item ,index) in project?.house_type" :key="item.id"
           @click="handleTabClick(index)">
        <span>{{ item?.title }}</span>
        <span>建筑面积约{{ item?.area }}㎡</span>
      </div>
    </div>
    <div class="houses-data">
      <div class="houses-image">
        <div class="image-title">
          <div class="title">
            <span>{{ house_type?.title }} 户型图</span>
          </div>
          <div class="browse" @click="handleBrowseClick">
            <img src="/static/images/fdj_ico4.png" alt="">
            <span>大图浏览</span>
          </div>
        </div>
        <div class="pic">
          <img :src=" house_type?.img" alt="">
        </div>
      </div>
      <div class="data">
        <div class="title">
          <span>{{ house_type?.title }}</span>
        </div>
        <div class="price-info">
          <div class="tit" style="width: 60px;">
            <span>价格</span>
          </div>
          <template v-if="house_type?.price_pending === 0">
            <div class="price">
              <span>{{ house_type?.price_start }}</span>
            </div>
            <div class="tit">
              <span>元/㎡</span>
            </div>
            <div class="tit">
              <span>&nbsp;&nbsp;&nbsp;&nbsp;至&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </div>
            <div class="price">
              <span>{{ house_type?.price_end }}</span>
            </div>
            <div class="tit">
              <span>元/㎡</span>
            </div>
          </template>
        </div>
        <div class="desc-info">
          <div class="desc">
            <span class="tit">户&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;型</span>
            <span>{{ house_type?.room }}</span>
            <span class="tit">建筑面积</span>
            <span>约{{ house_type?.area }}㎡</span>
          </div>
          <div class="desc">
            <span class="tit">朝&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;向</span>
            <span>{{ house_type?.towards }}</span>
          </div>
        </div>
        <div class="desc-info">
          <div class="desc">
            <span class="tit">户型介绍</span>
            <span class="ccc">{{ house_type?.description }}</span>
          </div>
          <div class="desc">
            <span class="tit">亮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;点</span>
            <span class="ccc">{{ house_type?.light }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HousesShowInfo',
  data() {
    return {
      id: 0,
      aindex: 0,
      project: false
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.aindex = parseInt(this.$route.query.index)
    this.fetchData()
  },
  updated() {
    this.aindex = parseInt(this.$route.query.index)
  },
  methods: {
    async fetchData() {
      let res = await this.http({
        url: '/api/report/projectInfo',
        method: 'GET',
        params: {
          project_id: this.$route.query.id
        }
      })

      res.data.base_data.primary_tag = res.data.base_data.primary_tags[0]?.tag || null

      this.project = res.data
    },
    handleBrowseClick() {
      window.open(
          this.house_type.img,
          '_blank'
      )
    },
    handleTabClick(index) {
      this.$router.push(
          {
            name: 'HousesShowInfo',
            query: {
              id: this.$route.query.id,
              index: index,
            }
          }
      )
    },
  },
  computed: {
    house_type() {
      if (this.project?.house_type && this.project?.house_type[this.aindex]) {
        return this.project?.house_type[this.aindex]
      } else {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "HousesShowInfo";
</style>
